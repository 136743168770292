import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from "vue"


    const __default__ = { name: 'Tabbar' }

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


const active = ref(0);
const onChange = (index: number) => {
  active.value = index;
};



return (_ctx: any,_cache: any) => {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item")!
  const _component_van_tabbar = _resolveComponent("van-tabbar")!

  return (_openBlock(), _createBlock(_component_van_tabbar, {
    fixed: "",
    placeholder: "",
    route: "",
    modelValue: active.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((active).value = $event)),
    onChange: onChange,
    ref: "tab"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_tabbar_item, {
        replace: "",
        to: "/home",
        icon: "wap-home"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("首页")
        ])),
        _: 1
      }),
      _createVNode(_component_van_tabbar_item, {
        replace: "",
        to: "/my",
        icon: "manager"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("我的")
        ])),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})