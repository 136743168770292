import { createApp, TransitionGroup } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'lib-flexible/flexible'
import { storage } from './storage/index'
import 'xe-utils'
import VXETable from 'vxe-table'
import VxeUIAll from 'vxe-pc-ui'
import VxeUITable from 'vxe-table'
import 'vxe-table/lib/style.css'
import 'vxe-pc-ui/lib/style.css'
import { ConfigProvider, Lazyload } from 'vant';




// 包括CSS
import "vue-data-ui/style.css"

router.beforeEach((to, from, next) => {
  let status=Number(storage.get('islogin'));
  let isLogin = Boolean(status);

  if (to.name !== 'Login' && !isLogin)
    next({ name: 'Login' })
  else
    sessionStorage.setItem('routerName', to.path)
  next()

})

const app = createApp(App)
app.use(VxeUIAll)
app.use(VxeUITable)
app.use(ConfigProvider)
app.use(VXETable)
app.use(store)
app.use(router)
app.use(Vant)
app.use(Lazyload)
app.mount('#app')
