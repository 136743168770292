import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "div" }

import Tabbar from "@/components/Tabbar/index.vue"
import { onMounted, ref } from "vue";
import { storage } from "./storage";
import { getmark } from "@/utils/watermark";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const tabShow = ref(true)

const { mark } = getmark();
onMounted(() => {
    const div = document.getElementById("div") as any;
    const div2 = div?.childNodes[0].childNodes[0] as any;
    storage.set("tabbarHeight", div2.offsetHeight)
    mark()
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, { include: "OrderFind,ReplenishmentBH" }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }),
    _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"height":"34px"} }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      (tabShow.value)
        ? (_openBlock(), _createBlock(Tabbar, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})